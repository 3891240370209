<template>
  <div class="vipinfo">
    <!-- 头部 -->
    <top></top>
    <div class="box">
      <!-- 内容 -->
      <div class="content_box">
        <!-- 这个是背景图片 -->
        <div class="background">
          <img src="../assets/home/100.png" alt="" />
        </div>
        <!-- 内容 -->
        <div class="content" style="display: block;">
          <!-- 标题 -->
          <div class="background_font">
            <div class="background_font_box">
              <img src="../assets/home/101.png" alt="" />
            </div>
          </div>
          <!-- 权益 -->
          <div class="three">
            <div class="equity">
              <div class="equity_content">
                <!-- 权益标题 -->
                <div class="equity_title">
                  <div class="equity_title_image">
                    <img style="width: 500px;" src="../../static/img/58.png" alt="" />
                  </div>
                </div>
                <div class="equity_box">
                    <!-- 第零个 -->
                    <div class="equity_box_one">
                        <ul>
                          <li>
                            <i><img src="../assets/home/150.png" alt="" /></i>
                          </li>
                          <li>解锁全站内容<br> &nbsp;</li>
                        </ul>
                      </div>
                  <!-- 第一个 -->
                  <div class="equity_box_one">
                    <ul>
                      <li>
                        <i><img src="../assets/home/copycancel.png" alt="" /></i>
                      </li>
                      <li>解锁复制粘贴功能</li>
                    </ul>
                  </div>
                  <!-- 第二个 -->
                  <div class="equity_box_one">
                    <ul>
                      <li>
                        <i><img src="../assets/home/151.png" alt="" /></i>
                      </li>
                      <li>定制业务与代运营业务折扣</li>
                    </ul>
                  </div>
                  <!-- 第三个 -->
                  <div class="equity_box_one">
                    <ul>
                      <li>
                        <i><img src="../assets/home/152.png" alt="" /></i>
                      </li>
                      <li>赠送运营、素材教程等资源</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 类型 -->
        <div class="type_box">
          <div class="type">
            <!-- 类型的标题 -->
            <div class="type_title">
              <div class="type_title_content">
                <img src="../assets/home/86.png" alt="" />
              </div>
            </div>
            <!-- 类型的内容 -->
            <div class="type_content">
              <!-- 第一个内容 -->
              <!-- 外框 -->
              <div v-for="(item,index) in monry" :key="item.id" :class="activeIndex == index ? 'btnAtive' : ''"
                @click="moneya(index,item)">
                <!-- 内容 -->
                <div class="type_tow">
                  <!-- 上 -->
                  <div class="type_tow_top">
                    <div class="div_buttom">
                      <h3>{{item.title}}</h3>
                    </div>
                  </div>
                  <!-- 中 -->
                  <div class="type_tow_midden">
                    <h4> {{item.secondtitle}}</h4>
                  </div>
                  <!-- 下 -->
                  <div class="type_tow_bottom">
                    <p><span>¥</span>{{item.price}}<span>元</span></p>
                    <p class="mprice" style="font-size: 20px;margin-bottom: 10px;text-decoration: line-through;">
                        <label style="font-size: 20px;">原价：¥</label>
                        <label>{{item.mprice}}</label>
                        <label style="font-size: 20px;">元</label>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 开通会员 -->
          <div class="type_button_box">
            <div class="type_button">
              <button @click="membera">开通会员</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <bottom></bottom>
    <!-- 侧边栏 -->
    <sidebar></sidebar>
  </div>
</template>
<script>
    import Qs from "qs";
    export default {
        data() {
            return {
                monry: [],
                activeIndex: -1,
                requestData: {},
                uiduid: '',
                useraimg: '',
                ordernumber: '',
            };
        },
        mounted() {
            this.searcha();
        },
        created() {
            var infostr = sessionStorage.getItem("key");
            var keyaaa = JSON.parse(infostr);
            this.uiduid = keyaaa.uid;

        },
        methods: {
            async searcha() {
                let that = this;
                this.$axios({
                        method: "post",
                        url: "/api/shopmini/wap_Viptype.html",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        data: Qs.stringify({}),
                    })
                    .then(function(res) {
                        that.monry = res.data.data;
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            },
            // 开通会员按钮
            membera() {
                // 判断是否选择会员类型
                if (this.activeIndex <= -1) {
                    this.$message({
                        showClose: true,
                        message: '请先选择一种会员类型',
                        type: 'warning'
                    });
                    return
                } else {
                    let that = this;
                    this.$axios({
                            method: "post",
                            url: "/api/payment/pcwxpayment_Native.html",
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded",
                            },
                            data: Qs.stringify({
                                id: that.requestData.id,
                                title: that.requestData.title,
                                price: that.requestData.price,
                                mprice: that.requestData.mprice,
                                uid: that.uiduid,
                            }),
                        })
                        .then(function(res) {
                            that.$router.push({
                                name: "Pay",
                                params: {
                                    title: that.requestData.title,
                                    price: that.requestData.price,
                                    pricd: res.data.url,
                                    ordernumber: res.data.dingdan,
                                },
                            });
                        })
                        .catch(function(error) {
                            console.log(error);
                        });
                }
            },
            // 会员类型按钮
            moneya(index, item) {
                this.requestData = item;
                // 选项卡
                this.activeIndex = index;

            },
        },
    };
</script>
<style scoped>
    img {
        vertical-align: top;
    }
    
    .btnAtive {
        background: #f62959 !important;
        color: red !important;
    }
    
    .btnAtive p {
        /* background: #f62959 !important; */
        color: #f62959 !important;
    }
    
    .btnAtive h3 {
        background: #f62959 !important;
        color: white !important;
        border-radius: 25px;
    }
    
    .btnAtive h4 {
        /* background: #f62959 !important; */
        color: white !important;
    }
    /* 内容 */
    
    .box {
        width: 100%;
        min-width: 1200px;
        margin-bottom: 50px;
        background-color: #000000;
    }
    
    .background_font {
        width: 100%;
        height: 440px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .background {
        width: 100%;
        min-width: 1200px;
        margin-top: 62px;
        position: absolute;
    }
    
    .background img {
        width: 100%;
        min-width: 1200px;
    }
    /* 内容 */
    
    .content {
        width: 100%;
        z-index: 100;
        position: relative;
    }
    /* 权益 */
    
    .equity {
        width: 1060px;
        height: 363px;
        margin: auto;
        background-image: linear-gradient(#ffd782, #cb9845);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
    }
    
    .equity_content {
        width: 1050px;
        margin: auto;
        height: 355px;
        background-color: #000000;
        border-radius: 30px;
    }
    /* 权益标题 */
    
    .equity_title {
        width: 100%;
        min-width: 1060px;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .equity_box {
        width: 100%;
        height: 223px;
        display: flex;
        justify-content: space-around;
    }
    
    .equity_box div {
        width: 33.3%;
        height: 100%;
    }
    
    .equity_box_one>ul {
        width: 100%;
        height: 223px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .equity_box_one>ul>li {
        text-align: center;
        color: #e3af59;
        font-size: 30px;
    }
    
    .equity_box_one>ul>li>i>img {
        width: 68px;
        height: 68px;
        vertical-align: top;
    }
    
    .equity_box_one>ul>li:nth-of-type(2) {
        width: 210px;
        margin-top: 10px;
    }
    /* 类型 */
    
    .type {
        width: 1060px;
        margin: auto;
    }
    
    .type_box {
        width: 100%;
        /* height: 446px; */
        z-index: 100;
        position: relative;
    }
    /* 类型的标题 */
    
    .type_title {
        width: 100%;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .type_content {
        width: 100%;
        height: 314px;
        display: flex;
        justify-content: space-between;
        /* background-color: red; */
    }
    
    .type_content>div {
        width: 330px;
        height: 100%;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: linear-gradient(#ffd782, #cb9845);
        /* border: 2px solid rgb(red, green, blue); */
        cursor: pointer;
    }
    
    .type_content>div:hover,
    .type_content>div:hover .div_buttom,
    .type_content>div:hover .type_tow_midden,
    .type_content>div:hover .type_tow_bottom p {
        background: #f62959 !important;
        color: white !important;
    }
    
    .type_content>div:hover .type_tow_midden {
        background: none !important;
    }
    
    .type_content>div:hover .type_tow_bottom p {
        background: none !important;
        color: #f62959 !important;
    }
    
    .type_content>div:hover .type_tow_bottom .mprice {
        color: #D3D3D3 !important;
    }
    
    .type_content>div>.type_tow {
        width: 320px;
        height: 304px;
        border-radius: 30px;
        background-color: #000000;
        display: flex;
        flex-direction: column;
    }
    
    .type_content>div>.type_tow>.type_tow_top {
        width: 100%;
        height: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .type_content>div>.type_tow>.type_tow_top>.div_buttom {
        width: 264px;
        height: 50px;
        background-image: linear-gradient(#ffd782, #cb9845);
        text-align: center;
        line-height: 50px;
        border-radius: 25px;
    }
    
    .type_content>div>.type_tow>.type_tow_midden {
        width: 100%;
        height: 30%;
    }
    
    .div_buttom {
        width: 100%;
        height: 40%;
        font-size: 24px;
        font-weight: bold;
        color: #020202;
    }
    /* 中 */
    
    .type_tow_midden {
        text-align: center;
        color: #e3af59;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
    }
    /* 下 */
    /* .type_tow_bottom {} */
    
    .type_tow_bottom span {
        font-size: 30px !important;
    }
    
    .type_tow_bottom p {
        align-items: center;
        justify-content: center;
        color: #fec675;
        font-size: 60px;
        text-align: center;
        font-weight: bold;
    }
    /* 开通会员 */
    
    .type_button_box {
        width: 100%;
        height: 200px;
    }
    
    .type_button {
        width: 1060px;
        height: 200px;
        margin: auto;
        display: flex;
        align-items: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .type_button>button {
        width: 240px;
        height: 60px;
        font-size: 24px;
        background-image: linear-gradient(#ffd782, #cb9845);
        border-radius: 30px;
        font-weight: bolder;
        color: #020202;
    }
</style>